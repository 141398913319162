export interface IPage {
	title: string;
	href: string;
	children?: IPage[];
}

export const allPages: IPage[] = [
	{
		title: 'Introduction to AA',
		href: '/',
	},
	{
		title: 'Getting Started',
		href: '/getting-started',
	},
	{
		title: 'Integration Overview',
		href: '/api-integration',
	},
	{
		title: 'Web SDK Integration',
		href: '/web-sdk-integration',
	},
	{
		title: 'Mobile SDK Integration',
		href: '/mobile-sdk-integration',
	},
	{
		title: 'Co-Development Guidelines',
		href: '/devops-guidelines',
	},
	// {
	// 	title: 'Finvu AA Client SDK',
	// 	href: '/sdk-integration',
	// 	children: [
	// 		{
	// 			title: 'SDK Integration',
	// 			href: '/sdk-integration',
	// 		},
	// 		{
	// 			title: 'Event Tracking',
	// 			href: '/event-tracking',
	// 		},
	// 	],
	// },
	{
		title: 'Split Consent Architecture',
		href: '/split-consent-architecture',
	},
	{
		title: 'Fastrack',
		href: '/fastrack',
		children: [
			// {
			// 	title: 'Authentication',
			// 	href: '/fastrack#auth',
			// },
			// {
			// 	title: 'FIP Latest Health API',
			// 	href: '/fastrack#health',
			// },
			// {
			// 	title: 'FIP Success Score API',
			// 	href: '/fastrack#score',
			// },
		],
	},
	{
		title: 'Lens',
		href: '/lens',
		children: [
			// {
			// 	title: 'Authentication',
			// 	href: '/lens#auth',
			// },
			// {
			// 	title: 'Discovery Summary API',
			// 	href: '/lens#discovery',
			// },
		],
	},
	
];
