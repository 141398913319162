import { Box, Typography, Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import React from "react";
import flowDiagram from "../assets/finvu working.png";
import { CenterFocusStrong } from "@mui/icons-material";
import DocNavigation from '../components/DocNavigation';
import { useLocation } from 'react-router-dom';

const HomePage: React.FC = React.memo(() => {
    const location = useLocation();
    return (
        <Box 
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: 2,
                paddingTop: 2
            }}
        >
            <Grid 
                container 
                justifyContent="center" 
                alignItems="center" 
                spacing={2} 
                direction="column"
            >
                <Grid item xs={12}>
                    <Box 
                    >   
                        <Typography                             
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: "1.2rem", sm: "1.5rem" },
                                color: "#0b3452",
                                display: 'inline-block',
                                marginBottom: 1,
                                lineHeight: 1.5,
                            }}>
                            Finvu's API Documentation
                        </Typography>
                        <Typography sx={{marginBottom:2.5,color: "#0b3452",fontWeight:'bold'}}> 
                            Unlock seamless data access with Finvu AA
                        </Typography>

                        
                        <Typography
                            component="p"
                            sx={{
                                color: "#555",
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                lineHeight: 1.7,
                                marginTop: 1,
                            }}
                        >
                           Finvu’s API suite enables seamless, secure, and user-consented data sharing between Financial Information Providers (FIPs) and Financial Information Users (FIUs). Our APIs empower FIUs with real-time access to comprehensive financial data from multiple sources, helping to build a complete view of customers’ financial profiles to elevate credit assessments, support smarter decision-making, empower personal finance management, and drive greater financial inclusivity.

                        </Typography>
                        <Typography
                            component="p"
                            sx={{
                                color: "#555",
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                lineHeight: 1.7,
                                marginTop: 1,
                            }}
                        >
                           With our core consent and data journey APIs, you can effortlessly manage and fulfill consent journeys and data retrieval processes. Additionally, the <b> Lens and FasTrack APIs </b> provide in-depth insights into these journeys, allowing you to analyze and optimize your funnels.

                        </Typography>

                        <Typography
                            component="p"
                            sx={{
                                color: "#555",
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                lineHeight: 1.7,
                                marginTop: 1,
                            }}
                        >
                           Our platform is designed to streamline your workflows while maximizing the efficiency and transparency of customer interactions and data processes.

                        </Typography>
                    </Box>
                    <Typography sx={{marginBottom:2.5,color: "#0b3452",fontWeight:'bold', paddingTop:'30px'}}> 
                            How Finvu Works ? 
                        </Typography>
                </Grid>
                <br></br>
                
                <Grid item xs={12}>
                    <Box
                        component="img"
                        src={flowDiagram}
                        alt="Finvu Account Aggregator"
                        sx={{
                            width: '100%',
                            maxWidth: { 
                                xs: '400px',  // Reduced from 500px
                                sm: '500px',  // Reduced from 600px
                                md: '700px',  // Reduced from 800px
                                lg: '800px',  // Reduced from 1000px
                                xl: '900px'   // Reduced from 1200px
                            },
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
            </Grid>
            <DocNavigation currentPath={location.pathname} />
        </Box>
    );
});

export default HomePage;
