import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = () => {
  const trigger = useScrollTrigger({
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Zoom in={trigger}>
      <Fab 
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 95,
          right: 20,
          backgroundColor: '#0b3452',
          '&:hover': {
            backgroundColor: '#0a2c44'
          }
        }}
        size="small"
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon sx={{ color: 'white' }}/>
      </Fab>
    </Zoom>
  );
};

export default ScrollToTop;
