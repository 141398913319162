import React, { useEffect } from 'react';
import { Box, Typography, Tabs, Tab, Link, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DocNavigation from '../components/DocNavigation';
import RapiDocReact from "../components/RapidDocReact";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const ApiIntegrationPage: React.FC = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedDemoTab, setSelectedDemoTab] = React.useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location.pathname]);

    useEffect(() => {
        if (location.hash === '#frontend') {
            setSelectedTab(1);
        } else {
            setSelectedTab(0);
        }
    }, [location.hash]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        navigate(`/api-integration${newValue === 1 ? '#frontend' : '#backend'}`);
    };

    const getNextTabPath = (currentTab: number) => {
        if (currentTab === 0) {
            return '/api-integration#frontend';
        }
        return '/web-sdk-integration';
    };

    const getPrevTabPath = (currentTab: number) => {
        if (currentTab === 1) {
            return '/api-integration#backend';
        }
        return '/getting-started';
    };

    const TabNavigation = ({ currentTab }: { currentTab: number }) => (
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            mt: 6,
            mb: 4,
            borderTop: '1px solid #e0e0e0',
            pt: 4
        }}>
            <Button 
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(getPrevTabPath(currentTab))}
                sx={{ color: '#0b3452' }}
            >
                {currentTab === 1 ? 'Backend Integration' : 'Getting Started'}
            </Button>
            <Button 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate(getNextTabPath(currentTab))}
                sx={{ color: '#0b3452' }}
            >
                {currentTab === 0 ? 'Frontend Integration' : 'Web SDK Integration'}
            </Button>
        </Box>
    );

    const BackendContent = () => (
        <Box>
            <Typography sx={{ mt: 3, color: "#555", lineHeight: 1.7 }}>
                If you are building your own FIU module, you will need to first develop the FIU functionality and the corresponding APIs. Finvu AA operates based on the ReBIT API specification and provides a secure interface for FIUs to interact with AA services.
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                For more detailed information about becoming an FIU, please visit the{' '}
                <Link
                    href="https://sahamati.org.in/financial-information-user-fiu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: '#0b3452',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    Sahamati FIU documentation
                </Link>

            </Typography>

            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Finvu AA Endpoints
            </Typography>
            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                All API requests should be made to the following base endpoints:
            </Typography>
            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                UAT:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace'
            }}>
                https://aauat.finvu.in/API/v2/
            </Box>
            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                Production:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace'
            }}>
                https://aalive.finvu.in/API/v2/
            </Box>

            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Prerequisites
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Client Authentication:</Box> You will need a valid client_api_key issued by Sahamati. This token is essential for authentication and must be included in every API call.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7, mt: 1 }}>
                        <Box component="span" fontWeight="bold">Request Signing:</Box> Every API request and response must be signed using a JWS (JSON Web Signature). The signature should be set in the x-jws-signature HTTP header. For more detailed information, please refer to the{' '}
                        <Link
                            href="https://tools.sahamati.org.in/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: '#0b3452',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Sahamati Tools Documentation
                        </Link>
                        .
                    </Typography>
                </li>
            </Box>

            {/* Getting Client API Token section */}
            <Typography 
                id="client-api-token"
                variant="h6" 
                sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}
            >
                Getting the Client API Token
            </Typography>
            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                To interact with the Finvu AA API, you need a valid client_api_key issued by Sahamati. For detailed information, please refer to the{' '}
                <Link
                    href="https://github.com/Sahamati/aa-common-service/blob/main/token-service/access-token-logistics.md"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: '#0b3452',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    Sahamati Token Service documentation
                </Link>
                . Here's a quick summary of the process:
            </Typography>

            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                Make a GET request to:
            </Typography>
            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                UAT:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace'
            }}>
                https://uattokens.sahamati.org.in/auth/realms/sahamati/protocol/openid-connect/token
            </Box>
            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                Production:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace'
            }}>
                https://tokens.sahamati.org.in/auth/realms/sahamati/protocol/openid-connect/token

            </Box>
            <Typography sx={{ mt: 3, mb: 2, color: "#555", fontWeight: "bold" }}>
                Set Authorization Details in your request or collection:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Authorization Type:</Box> OAuth 2.0
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Grant Type:</Box> Client Credentials
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Access Token URL:</Box> Use the URL provided by Sahamati for either the PRODUCTION or UAT environment
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Client ID:</Box> Set this to your entity ID in the Central Registry (CR)
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Client Secret:</Box> Use the client secret shared by Sahamati
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Scope:</Box> Set as openid
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Client Authentication:</Box> Select Send as Basic Auth Header
                    </Typography>
                </li>
            </Box>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Once you have successfully authenticated, you will receive the client_api_key, which you can use in your API calls.
            </Typography>



            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Required HTTP Headers
            </Typography>
            <Box sx={{
                mt: 2,
                '& td, & th': {
                    border: '1px solid #e0e0e0',
                    p: 1.5,
                    color: '#555'
                },
                '& th': {
                    bgcolor: '#f5f5f5',
                    fontWeight: 'bold'
                }
            }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>Header Key</th>
                            <th>Value</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                            <td>Specifies that the API request and response are in JSON format.</td>
                        </tr>
                        <tr>
                            <td>Accept</td>
                            <td>application/json</td>
                            <td>Indicates that the client expects a JSON response.</td>
                        </tr>
                        <tr>
                            <td>client_api_key</td>
                            <td>&#60;your Token&#62;</td>
                            <td>A unique token provided by Sahamati for authentication.</td>
                        </tr>
                        <tr>
                            <td>x-jws-signature</td>
                            <td>&#60;Detached JWS Signature&#62;</td>
                            <td>Contains the JWS signature of the request body.</td>
                        </tr>
                    </tbody>
                </table>
            </Box>

            {/* Example Header Values */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Example Values for Headers
            </Typography>

            <Typography sx={{ mt: 2, mb: 1, color: "#555", fontWeight: "bold" }}>
                client_api_key:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                wordBreak: 'break-all'
            }}>
                eyJraWQiOiJqYXlAZmludnUuaW4iLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJjb29raWVqYXI
                iLCJleHAiOjE1OTEyNjMwMDMsImp0aSI6IjRxeXFpWV9aZGJpTW1qQ2lqT0ZMckEiLCJpYXQ
                iOjE1NTk3MjcwMDMsIm5iZiI6MTU1OTcyNzAwMywic3ViIjoiamF5QGZpbnZ1LmluIiwiZ3J
                vdXBzIjpbImFnZ3JlZ2F0b3IiXX0.Ctd9EUpn0CrfEG8b9ouBcG8ilVuPWzXkxZFOhuTCRdp
                iVzi6M05ABJ9THJ1QE4n__r74r8KHSxDgdeV1L-8mek68i9m5BYgqaka5VKoCLiN0B22Xvy2
                QFski1ZLQhm7Va2NioVvilGGWBDlpW_csuChA2yobml1jqomr8SGya6MFbDURRmJjdL6oRTysoCYxeFzU2_JCud2ljMvaWsjSlnrYXQWaWaoJBAhz3AWNXdzU0u6mhq1mvksRl3xXJDiAVkY0KvZXRNjsJDYQfVJjavh9n0fZelJIWMHznp9mLpxRfrr_qo6hiTkX2QtbbmKfhc0_BXsKNOd
                xEXJmieOPSA
            </Box>

            <Typography sx={{ mt: 3, mb: 1, color: "#555", fontWeight: "bold" }}>
                x-jws-signature:
            </Typography>
            <Box sx={{
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                wordBreak: 'break-all'
            }}>
                eyJhbGciOiJSUzI1NiIsImtpZCI6IjUzMzEwNzkyLTgwMDYtNGU3NC1iYmJlLWNiZGRlYjg5
                ZjMwYiIsImI2NCI6ZmFsc2UsImNyaXQiOlsiYjY0Il19..h1FWwXanmcWgxUtHwlNWtD5_sE
                3HLjqd2rjE_c1WiPBvOHKJ4hs5oYlRdT663r7z7FV3WqP7tErNCzOlWUpu7j9JXKu7t4ObkC
                wx4pEZVySFcrI_0dpPAB43JlHeADeBwRX145tx6UC4yk4mIOTBrjdh2Yf0RZ7wOoyKys44mN
                qf0WJIZ2gO7bcm-WfTDW_5Y-
                tAO3LfyU2cQSyi1Jnc5q8qODXb_TsAYJOvANq0QGlstjEFwytnFsgPtyELjnjHMWgeTuDerw
                45xdNhdtNkR8UUHgajG2CFQwjEgpeOvZXbj0EeqbIgszXHsTHEfzRM6ZMMMEGcO_aXRUV3A6
                zZJQ
            </Box>

            {/* Add Sample Requests section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Request/Response Specifications
            </Typography>

            <Typography sx={{ mt: 2, mb: 3, color: "#555", lineHeight: 1.7 }}>
                Below are detailed request/response specifications for the Finvu AA APIs. For complete API documentation, please refer to the{' '}
                <Link
                    href="https://api.rebit.org.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: '#0b3452',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    ReBIT API Specification
                </Link>
                .
            </Typography>

            <RapiDocReact
                spec-url={process.env.PUBLIC_URL + '/AA_2_1_0.yaml'}
                show-info={false}
                show-header={false}
                render-style="view"
                primary-color="#0b3452"
                bg-color="#FAFBFC"
                allow-try={false}
                schema-style="table"
                font-size="large"
                text-color="#000000"
                regular-font='"Roboto","Helvetica","Arial",sans-serif'
                mono-font='"Roboto","Helvetica","Arial",sans-serif'
                default-api-server="https://aauat.finvu.in/API/v2"
                show-security={false}
                allow-authentication={false}
                allow-server-selection={false}
                show-curl-before-try={false}
            />
            <Box sx={{ 
                mt: 4, 
                p: 2, 
                bgcolor: '#F5F5F5', 
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}>
                <Typography sx={{ 
                    color: '#FF6C37', // Postman's orange
                    fontWeight: 700,
                }}>
                    Postman Collection
                </Typography>
                <Typography sx={{ 
                    color: '#FFFFFF',
                    fontSize: '0.9rem',
                    fontWeight: 700, 
                    bgcolor: '#FF6C37',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    ml: 1
                }}>
                    Coming Soon
                </Typography>
            </Box>

            <TabNavigation currentTab={0} />
        </Box>
    );

    const FrontendContent = () => (
        <Box>
            <Typography sx={{ mt: 3, color: "#555", lineHeight: 1.7 }}>
                FIU Integration with Finvu AA Client can be implemented via web or mobile through two approaches:
            </Typography>

            {/* 1. Redirection Approach */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                1. Redirection to Finvu AA Client
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        For guidelines on redirection, please refer to{' '}
                        <Link
                            href="https://sahamati.gitbook.io/aa-redirection-guidelines/1.2.1/specification/request-specification"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: '#0b3452',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Sahamati's Redirection Guidelines
                        </Link>
                        .
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        You can redirect users to the web-hosted Finvu AA domain from not only through your web app but also through your mobile app, either in a separate browser or within a WebView format.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        After commercials and NDA signing, Finvu will share the Websecret key required for creating the redirection URL.
                    </Typography>
                </li>
            </Box>

            {/* 2. Co-Creation Approach */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                2. Co-Creation of Consent Management Journeys
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        We can co-design consent management screens for web or mobile using our SDKs for JavaScript, Flutter, iOS, or Android.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        We can accept code contributions to a Finvu-managed repository, where Finvu will oversee code review, build management, and release deployment. Release cadence will be as mutually agreed upon.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        FIUs should follow the <Link
                            href="https://sahamati.org.in/wp-content/uploads/2023/05/IDEO-LMM-Sahamati-Collab-on-AA-Flow-v1-compressed.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: '#0b3452',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            IDEO-Sahamati design guidelines
                        </Link>. Please Ensure that L1 and L2 details are clearly visible to users on screen.
                        <br></br>
                        For more details, please refer to the{' '}
                        <Link
                            href="https://github.com/Sahamati/customer-experience-guidelines/blob/main/consent-guidelines.md"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: '#0b3452',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Sahamati's Customer Experience Guidelines
                        </Link>.
                    </Typography>
                </li>
            </Box>

            {/* Diagram Section 
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Redirection and Co-Creation Journey
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Box
                    component="img"
                    src="/images/frontend-client-relation.png"
                    alt="Redirection and Co-Creation Journey Diagram"
                    sx={{
                        width: '100%',
                        maxWidth: {
                            xs: '500px',  // Mobile screens
                            sm: '600px',  // Tablet screens
                            md: '800px',  // Small desktop
                            lg: '1000px', // Large desktop
                            xl: '1200px'  // Extra large screens
                        },
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto'
                    }}
                />
            </Box>
            */}

            {/* Add Figma Demo Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Interactive Demo
            </Typography>

            <Typography sx={{ mt: 2, mb: 3, color: "#555", lineHeight: 1.7 }}>
                Explore our user flows through these interactive prototypes:
            </Typography>

            <Tabs
                value={selectedDemoTab}
                onChange={(e, newValue) => setSelectedDemoTab(newValue)}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 2
                }}
            >
                <Tab label="Loan Underwriting" />
                <Tab label="Personal Finance Management" />
            </Tabs>

            <Box sx={{
                width: '100%',
                height: '800px',
                maxWidth: {
                    xs: '500px',
                    sm: '600px',
                    md: '800px',
                    lg: '1000px',
                    xl: '1200px'
                },
                border: '1px solid #eee',
                borderRadius: '4px',
                overflow: 'hidden',
                mb: 4,
                margin: '0 auto'
            }}>
                <iframe
                    style={{
                        border: 'none',
                        width: '100%',
                        height: '100%',
                        display: selectedDemoTab === 0 ? 'block' : 'none'
                    }}
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F7vghsa2zLdUcyQQe9LG7P2%2FLoan-Underwriting%3Fpage-id%3D11%253A96%26node-id%3D184-2518%26node-type%3Dcanvas%26viewport%3D63%252C327%252C0.04%26t%3Dqcek7A5Hfa4n3pBa-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D184%253A2518"
                    allowFullScreen
                />
                <iframe
                    style={{
                        border: 'none',
                        width: '100%',
                        height: '100%',
                        display: selectedDemoTab === 1 ? 'block' : 'none'
                    }}
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FvvgwmNN9AhTRUtHf09QYgP%2FPersonal-Finance-Management%3Fpage-id%3D11%253A2%26node-id%3D12-7081%26node-type%3Dframe%26viewport%3D3637%252C291%252C0.39%26t%3DyzKW0Ji8EAFIEw9R-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D12%253A7081"
                    allowFullScreen
                />
            </Box>

            <TabNavigation currentTab={1} />
        </Box>
    );

    return (
        <Box sx={{ paddingLeft: 4 }}>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    color: "#0b3452",
                    display: 'inline-block',
                    marginBottom: 2,
                    lineHeight: 1.5,
                }}
            >
                Integration Overview
            </Typography>

            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: 3
                }}
            >
                <Tab label="Backend Integration" />
                <Tab label="Frontend Integration" />
            </Tabs>

            {selectedTab === 0 && <BackendContent />}
            {selectedTab === 1 && <FrontendContent />}
        </Box>
    );
});

export default ApiIntegrationPage;
