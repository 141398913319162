import { Box, Typography, Grid, Link } from "@mui/material";
import React, { useEffect } from "react";
import BlackDot from "../components/BlackDot";
import DocNavigation from '../components/DocNavigation';
import { useLocation, useNavigate } from 'react-router-dom';

const GettingStartedPage: React.FC = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to top smoothly when the page loads
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location.pathname, location.hash]); // Add dependency on both pathname and hash

    const handleNavigation = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Box sx={{ paddingLeft: 4 }}>
            <Typography 
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    color: "#0b3452",
                    display: 'inline-block',
                    marginBottom: 1,
                    lineHeight: 1.5,
                }}
            >
                Getting Started with Account Aggregator
            </Typography>

            {/* AA Ecosystem Introduction */}
            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                The Account Aggregator (AA) framework is a revolutionary financial data-sharing system that enables individuals and businesses to securely share their financial information with third parties. This RBI-regulated framework acts as a secure bridge between Financial Information Providers (FIPs) and Financial Information Users (FIUs).
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Key benefits of the AA ecosystem:
            </Typography>

            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Secure and consent-based data sharing
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        User control over data access and duration
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Standardized data format across financial institutions
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Faster processing for financial services
                    </Typography>
                </li>
            </Box>

            {/* Sequence Diagram Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Detailed AA Flow Diagram
            </Typography>
            
            <Box sx={{ mt: 2, mb: 4 }}>
                <Box 
                    component="img"
                    src="/images/detailed-aa-flow.svg"
                    alt="Account Aggregator Flow Diagram"
                    sx={{
                        width: '100%',
                        maxWidth: '1200px',
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        border: '1px solid #e0e0e0',
                        borderRadius: '4px',
                        padding: 2,
                    }}
                />
            
            </Box>
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Finvu AA APIs
            </Typography>
            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Below is the detailed flow of AA APIs required to integrate with the Finvu Account Aggregator ecosystem:
            </Typography>

            {/* 1. Consent Initiation */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                1. Consent Initiation
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIU sends a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /Consent</Box> request to the AA, providing details like purpose code, consent start and expiry, data life, Fi fetch range and frequency
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA responds with a ConsentHandle, which acts as a unique reference for the consent request.
                    </Typography>
                </li>
            </Box>

            {/* 2. Consent UI Presentation */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                2. Consent UI Presentation
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The Customer is redirected to Finvu AA Client where they can discover their bank accounts, link them, and provide consent.
                    </Typography>
                </li>
            </Box>

            {/* 3. Consent Handling */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                3. Consent Handling
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Upon consent approval, the AA sends a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /Consent/Notification</Box> request to the FIU with the customer's consentId and status (APPROVED). The FIU responds with a <Box component="span" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>200 OK</Box> response.
                    </Typography>
                </li>
            </Box>

            {/* 4. Consent Retrieval */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                4. Consent Retrieval
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIU retrieves the signed consent by sending a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /Consent/fetch</Box> request with the consentId.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA responds with the signed consent and its status.
                    </Typography>
                </li>
            </Box>

            {/* 5. FIU to AA Data Request */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                5. FIU to AA Data Request
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIU requests data from the FIP by sending a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/request</Box> request to the AA, including the consentId and cryptographic KeyMaterial.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA creates a session for the request and responds with a sessionId.
                    </Typography>
                </li>
            </Box>

            {/* 6. AA to FIP Data Request */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                6. AA to FIP Data Request
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA requests data from the FIP by sending a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/request</Box> request to the FIP, including the consentId and cryptographic KeyMaterial.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIP creates a session for the request and responds with a <Box component="span" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>200 OK</Box> and sessionId.
                    </Typography>
                </li>
            </Box>

            {/* 7. Data Ready Notification */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                7. Data Ready Notification (FIP to AA)
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Once the requested data is ready, the FIP sends a Data Ready Notification to the AA by sending a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/Notification</Box> request, signaling that the data can be fetched.
                    </Typography>
                </li>
            </Box>

            {/* 8. Data Fetch Request */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                8. Data Fetch Request (AA to FIP)
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA sends a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/fetch</Box> request to the FIP to retrieve the encrypted data.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIP responds with a <Box component="span" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>200 OK</Box> and the encrypted data.
                    </Typography>
                </li>
            </Box>

            {/* 9. Data Ready Notification */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                9. Data Ready Notification (AA to FIU)
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Once the requested data is ready, the AA sends a Data Ready Notification to the FIU by sending a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/Notification</Box> request, signaling that the data can be fetched.
                    </Typography>
                </li>
            </Box>

            {/* 10. Data Fetching by FIU */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                10. Data Fetching by FIU
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The FIU sends a <Box component="span" sx={{ color: '#0b3452', fontWeight: 'bold' }}>POST /FI/fetch</Box> request to the AA, providing the sessionId.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The AA responds with a <Box component="span" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>200 OK</Box> and:
                        <ul>
                            <li>The encrypted data fetched from the FIP.</li>
                            <li>The KeyMaterial used for encryption, ensuring the FIU can decrypt and process the data.</li>
                        </ul>
                    </Typography>
                </li>
            </Box>

            {/* Key Highlights */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Key Highlights
            </Typography>
            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Consent Handle and Consent ID:</Box> These are unique identifiers for consent requests and approvals, ensuring traceability and security.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Session ID:</Box> Allows the tracking of data fetch requests, linking them securely to the approved consent.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">KeyMaterial:</Box> Implements robust encryption mechanisms like Diffie-Hellman to ensure secure data transfer between the AA, FIU, and FIP.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        <Box component="span" fontWeight="bold">Data Security:</Box> Ensures the customer's data is shared only with their consent and remains encrypted during transit.
                    </Typography>
                </li>
            </Box>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                This flow demonstrates how the Account Aggregator ensures a secure and consent-driven data-sharing ecosystem, prioritizing the customer's privacy at every step.
            </Typography>
            {/* Integration Section */}
            <Typography variant="h6" sx={{ mt: 5, color: "#0b3452", fontWeight: "bold" }}>
                Integrating with Finvu AA
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Ready to integrate with Finvu AA? Our integration process is designed to get you up and running with both backend and frontend implementations. As a Financial Information User (FIU), you'll have access to our comprehensive suite of APIs and SDKs that enable secure data sharing while maintaining complete user control and consent.
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                The integration journey consists of two main components:
            </Typography>

            <Box component="ul" sx={{ mt: 1, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7, fontWeight: "bold" }}>
                        Backend Integration
                    </Typography>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Build your FIU module using our ReBIT-compliant APIs. We provide a secure interface with comprehensive authentication and request signing mechanisms to ensure data safety.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7, mt: 2, fontWeight: "bold" }}>
                        Frontend Integration
                    </Typography>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Choose between our ready-to-use web/mobile SDKs or create custom consent management screens following Sahamati's design guidelines.
                    </Typography>
                </li>
            </Box>

            <Typography sx={{ mt: 3, color: "#555", lineHeight: 1.7 }}>
                Let's explore each integration path in detail to help you choose the best approach for your needs.
            </Typography>

            <DocNavigation 
                currentPath={location.pathname} 
                onNavigate={handleNavigation}  // Add navigation handler
            />
        </Box>
    );
});

export default GettingStartedPage;
