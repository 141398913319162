import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { allPages } from '../models/Page';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface DocNavigationProps {
    currentPath: string;
    onNavigate?: () => void;
}

const DocNavigation: React.FC<DocNavigationProps> = ({ currentPath, onNavigate }) => {
    const navigate = useNavigate();
    const flatPages = allPages.flatMap(page => 
        [page, ...(page.children || [])]
    );
    
    const currentIndex = flatPages.findIndex(page => page.href === currentPath);
    const prevPage = currentIndex > 0 ? flatPages[currentIndex - 1] : null;
    const nextPage = currentIndex < flatPages.length - 1 ? flatPages[currentIndex + 1] : null;

    const handleNavigation = (path: string) => {
        navigate(path);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        onNavigate?.();
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            mt: 6,
            mb: 4,
            borderTop: '1px solid #e0e0e0',
            pt: 4
        }}>
            {prevPage && (
                <Button 
                    startIcon={<ArrowBackIcon />}
                    onClick={() => handleNavigation(prevPage.href)}
                    sx={{ color: '#0b3452' }}
                >
                    {prevPage.title}
                </Button>
            )}
            <Box flexGrow={1} />
            {nextPage && (
                <Button 
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => handleNavigation(nextPage.href)}
                    sx={{ color: '#0b3452' }}
                >
                    {nextPage.title}
                </Button>
            )}
        </Box>
    );
};

export default DocNavigation;
