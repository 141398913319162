import React, { useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DocNavigation from '../components/DocNavigation';

const SplitConsentArchitecture: React.FC = React.memo(() => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [location.pathname]);

	return (
		<Box sx={{ paddingLeft: 4 }}>
			<Typography
				sx={{
					fontWeight: 'bold',
					fontSize: { xs: '1.2rem', sm: '1.5rem' },
					color: '#0b3452',
					display: 'inline-block',
					marginBottom: 0,
					lineHeight: 1.5,
				}}
			>
				Split Consent Architecture
			</Typography>

			<Typography
				variant="subtitle1"
				sx={{ mt: 4, color: '#0b3452', fontWeight: 'bold' }}
			>
				Introduction
			</Typography>

			<Typography sx={{ mt: 2, color: '#555', lineHeight: 1.7 }}>
				This document provides Financial Information Users (FIUs) with a
				comprehensive guide to implementing the split consent architecture in
				co-developed journeys. The split consent architecture enables FIUs to
				create one consent artefact per account or per Financial Information
				Provider (FIP), enhancing flexibility and robustness in the consent
				management process.
			</Typography>

			<Typography sx={{ mt: 2, color: '#555', lineHeight: 1.7 }}>
				In the current architecture, the FIU server initiates a single consent
				request, which is then presented to the customer via the AA client. The
				customer uses the AA client to discover and link accounts, subsequently
				providing consent to share the data of these linked accounts with the
				FIU. Upon the customer's approval, a single FIU consent artefact is
				generated, encompassing all accounts consented to during the AA journey.
			</Typography>

			<Typography
				variant="subtitle1"
				sx={{ mt: 4, color: '#0b3452', fontWeight: 'bold' }}
			>
				Benefits of the Split Consent Architecture
			</Typography>

			<Box component="ul" sx={{ mt: 2, color: '#555', pl: 3 }}>
				<Typography sx={{ lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						1. Resilience to Post Consent Approval Failures
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					Sometimes, when a user approves the consent, the FIP rejects the
					consent artefact sent by the account aggregator. Due to this, a
					scenario arises where a customer which has accounts with multiple FIPs
					has approved the consent, but the consent gets rejected entirely due
					to rejection by any one of the FIPs.
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					To avoid this scenario, the split consent architecture ensures that
					the consent artefact generated is on an account or FIP-level, i.e. if
					any one FIP rejects the consent artefact, the FIU can still fetch the
					account information (as per consent request details) for the other
					FIPs as each account/FIP has their own consent artefact.
				</Typography>
				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						2. Independent Revocation of Consent
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					Similar to rejection of consent artefact post approval, the revocation
					of a consent can be done, with the help of split consent architecture,
					at an account/FIP level.
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					Users can, by this method, control the data they share with FIUs at an
					account level by revoking consents associated per account or per FIP.
				</Typography>
			</Box>

			{/* How it Works Section */}
			<Typography
				variant="subtitle1"
				sx={{ mt: 4, color: '#0b3452', fontWeight: 'bold' }}
			>
				How the Split Consent Architecture Works
			</Typography>

			<Box component="ul" sx={{ mt: 2, color: '#555', pl: 3 }}>
				<Typography sx={{ lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						1. Initial Consent Request Initiation
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					The FIU server initiates an initial consent request for the customer
					with the AA server and gets the initial consent handle.
				</Typography>

				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						2. Presentation of Consent Request
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					Once on the AA Client, the consent request is presented to the
					customer on the interface post-authentication.
				</Typography>

				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						3. Account Discovery
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					The customer discovers their accounts on the AA Client.
				</Typography>

				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						4. Account Linking
					</Box>
				</Typography>
				<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
					The customer links their discovered accounts on the AA Client.
				</Typography>

				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						5. Consent Request Approval
					</Box>
				</Typography>
				<Box component="ul" sx={{ pl: 3 }}>
					<li>
						<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
							Post linking and approval on the AA client by the customer, the AA
							client communicates to the FIU server to request the retrieve
							additional consent handles (by raising consent requests with the
							AA) at an account/FIP level (same as the number of accounts
							selected for consent approval minus one<sup>1</sup>) with the same
							consent request parameters as in the initial consent request.
						</Typography>
					</li>
					<li>
						<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
							As an example, consider a scenario where a customer has N
							accounts/FIPs selected for approval. In this case, the AA client
							prompts the FIU server to sequentially<sup>2</sup> retrieve N-1
							additional consent handles, which along with the initial consent
							handle, makes N consent handles, one per account/FIP.
						</Typography>
					</li>
					<li>
						<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
							Once the AA Client gets the consent handle post-linking, the
							consent is approved for that account/FIP using that consent
							handle. This process happens till all the consent handles per
							account/FIP are approved.
						</Typography>
					</li>
				</Box>
			</Box>

			<Box sx={{ borderBottom: '1px solid #e0e0e0', mt: 4, mb: 4 }} />

			{/* Technical Notes */}
			<Box sx={{ mt: 2, mb: 4, color: '#666', fontSize: '0.875rem' }}>
				<Typography sx={{ fontSize: 'inherit', color: 'inherit', mb: 1 }}>
					<sup>1</sup> minus one because the initial consent handle is also used
					to approve the consent for one of the accounts or one of the FIPs
				</Typography>
				<Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>
					<sup>2</sup> sequence: linking → customers' approval on the frontend →
					retrieval of additional consent handles → approval of consent per
					account/FIP via API call
				</Typography>
			</Box>

			<Typography sx={{ mt: 2, color: '#555', lineHeight: 1.7 }}>
				Refer to the sequence diagram below for more details:
			</Typography>

			<Box sx={{ mt: 2, mb: 4 }}>
				<Box
					component="img"
					src="/images/split-consent.svg"
					alt="Split Consent Architecture Flow"
					sx={{
						width: '90%',
						maxWidth: '1200px',
						height: 'auto',
						display: 'block',
						margin: '0 auto',
						border: '1px solid #e0e0e0',
						borderRadius: '4px',
						padding: 2,
					}}
				/>
			</Box>

			{/* Additional Note */}
			<Box sx={{ mt: 2, mb: 4 }}>
				<Typography
					sx={{ color: '#555', lineHeight: 1.7, fontStyle: 'italic' }}
				>
					*Note: FIUs may opt to change the sequence of steps 4 (linking) and 5
					(consent approval). In this case:
				</Typography>
				<ul>
					<li>
						<Typography sx={{ mt: 2, color: '#555', lineHeight: 1.7 }}>
							User approval of consent on the AA client is tracked and the
							consent is deemed "approved" with the selected accounts, but this
							does not trigger backend server calls, meaning the consent
							artefact is not generated at this step. After the deemed approval,
							account linking takes place and the consent handle(s) are
							retrieved as the accounts are linked. After receiving the consent
							handle(s), the actual backend server calls happen and the consent
							artefact per account or per FIP is generated.
						</Typography>
					</li>

					<li>
						<Typography sx={{ mt: 2, color: '#555', fontWeight: 'bold' }}>
							Sequence of events in this case:
						</Typography>
						<Typography sx={{ mt: 1, color: '#555', lineHeight: 1.7 }}>
							Approval by the customer on the frontend → Linking → Retrieval of
							additional consent handles → Approval of the consents per
							account/FIP via API call
						</Typography>
					</li>
				</ul>
			</Box>

			<Box
				sx={{
					borderBottom: '1px solid #e0e0e0',
					mb: 4,
				}}
			/>

			{/* Important Notes Section */}
			<Typography
				variant="subtitle1"
				sx={{ mt: 4, color: '#0b3452', fontWeight: 'bold' }}
			>
				Important Notes
			</Typography>

			<Box component="ul" sx={{ mt: 2, color: '#555', pl: 3 }}>
				<Typography sx={{ lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						1. Usage of consent handles
					</Box>
				</Typography>
				<li>
					<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
						The initial consent handle generated at the start of the AA journey
						is to be used with the additional consent handles to approve
						consents per account/FIP.
					</Typography>
				</li>
				<li>
					<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
						This ensures that the initial consent handle is also utilized
						efficiently and does not remain in a PENDING state.
					</Typography>
				</li>

				<Typography sx={{ mt: 2, lineHeight: 1.7 }}>
					<Box component="span" fontWeight="bold">
						2. Generating Additional Consent Handles
					</Box>
				</Typography>
				<li>
					<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
						FIU must use the same consent request parameters as the initial
						consent request (which is presented to the customer on the AA
						Client) while requesting the AA for additional consent handles.
					</Typography>
				</li>
				<li>
					<Typography sx={{ mt: 1, lineHeight: 1.7 }}>
						While requesting the AA for any additional consent handles, certain
						request parameters such as customer ID and consent details are
						required. To track these, FIUs can maintain a workflow ID. This
						workflow ID will be sent initially when the AA Client is
						initialized. This way, when additional consent handles are
						requested, this workflow ID can be sent to the FIU Server to
						reference the consent request parameters for consent handle
						retrieval.
					</Typography>
				</li>
			</Box>

			{/* Further Assistance Section */}
			<Typography
				variant="subtitle1"
				sx={{ mt: 4, color: '#0b3452', fontWeight: 'bold' }}
			>
				Further Assistance
			</Typography>

			<Typography sx={{ mt: 2, color: '#555', lineHeight: 1.7 }}>
				For additional support or queries, please contact the Finvu support team
				at{' '}
				<Link href="mailto:support@cookiejar.co.in" sx={{ color: '#0b3452' }}>
					support@cookiejar.co.in
				</Link>
				.
			</Typography>

			<DocNavigation currentPath={location.pathname} />
		</Box>
	);
});

export default SplitConsentArchitecture;
