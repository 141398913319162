import React from 'react';
import { Grid, Typography, ListItem } from '@mui/material';
import BlackDot from './BlackDot';

function TwoColumnList() {
  return (
    <Grid container spacing={2} sx={{marginBottom:1.5}}>
      <Grid item xs={6}>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           1️⃣ Consent Request-Response from FIU → AA
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           2️⃣ Consent Approval Request-Response AA → Customer
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            3️⃣ Consent Post Request-Response from AA → FIP
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            4️⃣ Consent Status Notification from AA → FIU
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            5️⃣ Consent Fetch Request-Response from FIU → AA
          </Typography>
        </ListItem>
      </Grid>
    </Grid>
  );
}

export default TwoColumnList;
