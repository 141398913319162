import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import DocNavigation from '../components/DocNavigation';
import { useLocation } from 'react-router-dom';

const DevOpsGuidelinesPage: React.FC = React.memo(() => {
    const location = useLocation();

    return (
        <Box sx={{ paddingLeft: 4 }}>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    color: "#0b3452",
                    display: 'inline-block',
                    marginBottom: 2,
                    lineHeight: 1.5,
                }}
            >
                DevOps Guidelines for Co-Developing AA Client Interfaces
            </Typography>

            {/* Introduction */}
            <Typography sx={{ color: "#555", lineHeight: 1.7, mb: 3 }}>
                FIUs (either directly or via their TSPs) can collaborate with Finvu AA to co-design and deploy custom AA Client Interfaces, 
                including web and mobile applications. These interfaces are hosted on the <Box component="span" sx={{ color: '#0b3452' }}>discover.finvu.in </Box>
                sub-domain and serve the FIUs' customers. This document outlines the pre-requisites and guidelines for deploying and maintaining these interfaces.
            </Typography>

            {/* DevOps Overview Diagram */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                DevOps Process Overview
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Box
                    component="img"
                    src="/images/devops-overview.svg"
                    alt="DevOps Process Overview Diagram"
                    sx={{
                        width: '100%',
                        maxWidth: {
                            xs: '400px',  // Mobile screens
                            sm: '500px',  // Tablet screens
                            md: '600px',  // Small desktop
                            lg: '700px',  // Large desktop
                            xl: '800px'   // Extra large screens
                        },
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto'
                    }}
                />
            </Box>

            {/* FIU Onboarding Process */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                FIU Onboarding Process
            </Typography>
            <Typography sx={{ color: "#555", mt: 1 }}>
                To begin integrating with Finvu AA, follow these steps:
            </Typography>
            <Box component="ol" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Contact the Finvu AA onboarding team:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Send your FIU ID to{' '}
                                <Link href="mailto:onboarding@cookiejar.co.in" sx={{ color: '#0b3452' }}>
                                    onboarding@cookiejar.co.in
                                </Link>
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Request onboarding for both staging and production environments
                            </Typography>
                        </li>
                    </Box>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        The onboarding team will:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Enable your FIU on the Finvu platform
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Generate and share your Client Secret Key
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                First enable the FIU on the staging environment for testing
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Once testing is successful, enable the FIU on the production environment
                            </Typography>
                        </li>
                    </Box>
                </li>
            </Box>

            {/* Pre-requisites */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Pre-requisites
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        FIU/TSP to share their developer github accounts to Finvu.
                    </Typography>
                </li>
            </Box>

            {/* Repository Setup and Structure */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Repository Setup and Structure
            </Typography>

            {/* 1. GitHub Repository Creation */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. GitHub Repository Creation:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Each FIU gets a dedicated repository
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Repository naming convention: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>fiu-journey-[FIU_Name]</Box>
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Repository URL format: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>https://github.com/Cookiejar-technologies-fiu-journey/fiu-journey-[FIU_Name]</Box>
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Code management: Source code for FIU-specific UI implementations
                    </Typography>
                </li>
            </Box>

            {/* TSP-Managed FIU Integration */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                TSP-Managed FIU Integration
            </Typography>
            <Typography sx={{ color: "#555", mt: 1 }}>
                When a Technical Service Provider (TSP) manages multiple FIUs with a common UI:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Single repository per TSP
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Repository naming convention: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>fiu-journey-[TSP_Name]</Box>
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Repository URL format: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>https://github.com/Cookiejar-technologies-fiu-journey/fiu-journey-[TSP_Name]</Box>
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Code management:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Single codebase for common UI components
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Separate main branch for each FIU client
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Each FIU's configuration and customizations maintained in their respective main branch
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Shared components and common code accessible across all FIU branches
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Separate GitHub workflow files for each FIU client
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                TSP maintains distinct configurations per FIU while sharing common code
                            </Typography>
                        </li>
                    </Box>
                </li>
            </Box>

            {/* 2. Branching Strategy */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                2. Branching Strategy:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Main Branches:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                stage branch: This branch is used for deploying code to the Staging (Test) environment, where new features and bug fixes are tested before being deployed to Production.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                main branch: This branch is used for deploying code to the Production (PROD) environment, where code is made available to end users.
                            </Typography>
                        </li>
                    </Box>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Custom Branches:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Developers can create their own feature or hotfix branches with the following recommended branch-naming conventions. 
                                These custom branches allow developers to work on specific features or fixes independently before merging them into the main branches.
                            </Typography>
                        </li>
                    </Box>
                </li>
            </Box>

            {/* 3. Branch Naming Conventions */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                3. Branch Naming Conventions:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Feature Branch:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Naming: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>&lt;fiu/tsp_name&gt;_&lt;feature_name&gt;</Box>
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Tag: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>feature</Box>
                            </Typography>
                        </li>
                    </Box>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Hotfix Branch:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Naming: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>&lt;fiu/tsp_name&gt;_&lt;feature_name&gt;</Box>
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Tag: <Box component="code" sx={{ bgcolor: 'rgba(135, 131, 120, 0.15)', px: 1, borderRadius: 1 }}>hotfix</Box>
                            </Typography>
                        </li>
                    </Box>
                </li>
            </Box>

            {/* Development and Deployment Process */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Development and Deployment Process
            </Typography>

            {/* 1. Code Contribution */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. Code Contribution:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers commit code to their respective branches, following best practices.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers to ensure proper tagging and naming conventions are used for all pull requests (PRs).
                    </Typography>
                </li>
            </Box>

            {/* 2. Deployment to Staging environment */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                2. Deployment to Staging environment:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers to raise a pull request (PR) to the stage branch, adding Finvu's development team as reviewers.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers will create a Github workflow file for deploying the application. Any secrets/environment variables to be added such as Azure storage account name, account key will be added by the reviewer in the Finvu team. A sample of the Github workflow will be provided to the developers of the FIU/TSP team, if needed.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Finvu will review the code and the workflow based on certain design and compliance guidelines.
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Review + Deployment SLOs:
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Feature Branch: within 3 days
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Hotfix Branch: within 5 hours
                            </Typography>
                        </li>
                    </Box>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Once the review is complete and the PR is approved, code will be built and deployed to the staging environment.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Notifications will be sent to FIU/TSP developers upon deployment.
                    </Typography>
                </li>
            </Box>

            {/* Sample Github Workflow */}
            <Typography sx={{ mt: 3, color: "#555", fontStyle: 'italic' }}>
                Sample Github Workflow File:
            </Typography>
            <Box 
                component="pre"
                sx={{ 
                    mt: 2,
                    p: 2,
                    bgcolor: 'rgba(135, 131, 120, 0.15)',
                    borderRadius: 1,
                    overflow: 'auto'
                }}
            >
                <code>{`name: Build and Deploy React App

on:
  push:
    branches:
      - develop # Trigger deployment on changes to the main branch

jobs:
  build:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Set up Node.js
        uses: actions/setup-node@v3
        with:
          node-version: '16' # Match your project's Node.js version

      - name: Install dependencies
        run: npm install

      - name: Set Environment Variables
        run: |
          echo "Using UAT environment"
          echo "CI_ENV_FILE=.env.finvu_uat" >> $GITHUB_ENV

      - name: Build the project
        run: npm run build

      - name: Deploy to Azure Storage
        uses: azure/CLI@v1
        with:
          inlineScript: |
            az storage blob upload-batch \\
              --account-name <storage_account_name> \\
              --source ./build \\
              --destination '$web' \\
              --overwrite
        env:
          AZURE_STORAGE_ACCOUNT: \${{ secrets.<storage_account_name> }}
          AZURE_STORAGE_KEY: \${{ secrets.<storage_account_key> }}`}</code>
            </Box>

            {/* 3. Testing and QA */}
            <Typography variant="subtitle1" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                3. Testing and QA:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers are responsible for testing in the staging environment.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Test Cases Execution:
                    </Typography>
                    <Box component="ul" sx={{ pl: 3 }}>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Automated test cases will be run during the build process, if any. (with the Github workflow file).
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ lineHeight: 1.7 }}>
                                Developers may include unit and integration test results as part of the PR.
                            </Typography>
                        </li>
                    </Box>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Finvu will verify the journey once code is deployed.
                    </Typography>
                </li>
            </Box>

            {/* 4. Deployment to Production */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                4. Deployment to Production:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: "#555", pl: 3 }}>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        After successful testing in staging, developers raise a pull request for the main branch.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        A release document outlining the changes for production deployment must be attached.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Finvu will review, build, and deploy the main branch to the production environment.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Deployment status will be communicated synchronously.
                    </Typography>
                </li>
                <li>
                    <Typography sx={{ lineHeight: 1.7 }}>
                        Developers are required to maintain release versions with tags in case there is a need for a rollback to any previous version.
                    </Typography>
                </li>
            </Box>

            <DocNavigation currentPath={location.pathname} />
        </Box>
    );
});

export default DevOpsGuidelinesPage;
