import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeLayout from './components/HomeLayout';
import { Container } from '@mui/material';
import Fastrack from './pages/Fastrack';
import HomePage from './pages/HomePage';
import LensPage from './pages/Lens';
// import WebsocketApiPage from './pages/WebsocketApiPage';
// import FinvuEventTrackingComponent from './pages/EventTracking';
import GettingStartedPage from './pages/GettingStartedPage';
import ApiIntegrationPage from './pages/ApiIntegrationPage';
// import SDKIntegration from './pages/SDKIntegration';
import DevOpsGuidelinesPage from './pages/DevOpsGuidelinesPage';
import WebSDKIntegration from './pages/WebSDKIntegration';
import SplitConsentArchitecture from './pages/SplitConsentArchitecture';
import MobileSDKIntegration from './pages/MobileSDKIntegration';

export default function App() {
	return (
		<Container disableGutters component="div" maxWidth={false}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomeLayout />}>
						<Route path="" element={<HomePage />} />
						<Route path="/getting-started" element={<GettingStartedPage />} />
						<Route path="/fastrack" element={<Fastrack />} />
						<Route path="/lens" element={<LensPage />} />
						{/* <Route path="/sdk-integration" element={<SDKIntegration />} /> */}
						{/* <Route path="/api" element={<WebsocketApiPage />} /> */}
						<Route path="/api-integration" element={<ApiIntegrationPage />} />
						{/* <Route path='/event-tracking' element={<FinvuEventTrackingComponent />}/> */}
						{/* <Route path="/finvu-aa-client-sdk" element={<WebsocketApiPage />} /> */}
						<Route path="/devops-guidelines" element={<DevOpsGuidelinesPage />} />
						<Route path="/web-sdk-integration" element={<WebSDKIntegration />} />
						<Route path="/split-consent-architecture" element={<SplitConsentArchitecture />} />
						<Route path="/mobile-sdk-integration" element={<MobileSDKIntegration />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</Container>
	);
}
