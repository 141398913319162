import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DocNavigation from '../components/DocNavigation';
import { CopyBlock, dracula } from "react-code-blocks";

const MobileSDKIntegration: React.FC = React.memo(() => {
    const location = useLocation();
    const [selectedPlatform, setSelectedPlatform] = useState('android');

    const handlePlatformChange = (platform: string) => {
        setSelectedPlatform(platform);
    };

    return (
        <Box sx={{ paddingLeft: 4 }}>
            {/* Title */}
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    color: "#0b3452",
                    display: 'inline-block',
                    marginBottom: 2,
                    lineHeight: 1.5,
                }}
            >
                Mobile SDK Integration
            </Typography>

            {/* Introduction */}
            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Welcome to the integration guide for Finvu Mobile SDK! This document provides detailed instructions 
                on integrating our SDK into your mobile application. Choose your platform below to get started.
            </Typography>

            {/* Prerequisites Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Prerequisites
            </Typography>

            {/* Platform Selector with Prerequisites */}
            <Box sx={{ mt: 3 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Prerequisites Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <Box component="ul" sx={{ m: 0, pl: 3 }}>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Minimum SDK version 24
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Minimum Kotlin version 1.9.0
                                    </Typography>
                                </li>
                            </Box>
                        )}
                        {selectedPlatform === 'ios' && (
                            <Box component="ul" sx={{ m: 0, pl: 3 }}>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Minimum iOS version 13
                                    </Typography>
                                </li>
                            </Box>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <Box component="ul" sx={{ m: 0, pl: 3 }}>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Dart SDK version ≥3.3.0 &lt;4.0.0
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Flutter version ≥3.3.0
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        Android: Minimum SDK version 24
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{ lineHeight: 1.7 }}>
                                        iOS: Minimum iOS version 13
                                    </Typography>
                                </li>
                            </Box>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* Installation Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Installation
            </Typography>

            <Box sx={{ mt: 3 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Installation Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    1. Add the following repository to your project level <code>build.gradle</code> file:
                                </Typography>
                                <CopyBlock
                                    text={`// Project level build.gradle
allprojects {
    repositories {
        // Standard repositories
        google()
        mavenCentral()
        
        // Finvu SDK repository
        maven { 
            url 'https://maven.pkg.github.com/Cookiejar-technologies/finvu_android_sdk' 
            credentials {
                username = System.getenv("GITHUB_PACKAGE_USERNAME")
                password = System.getenv("GITHUB_PACKAGE_TOKEN")
            }
        }
    }
}`}
                                    language="groovy"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    2. Add the following in app level <code>build.gradle</code> file:
                                </Typography>
                                <CopyBlock
                                    text={`// App level build.gradle
android {
    defaultConfig {
        // Minimum SDK version required by Finvu SDK
        minSdkVersion 24
    }
}`}
                                    language="groovy"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Add the following to your <code>Podfile</code>:
                                </Typography>
                                <CopyBlock
                                    text={`# Podfile configuration
target 'Runner' do
    # Use frameworks for Swift support
    use_frameworks!
    use_modular_headers!

    # Set minimum iOS version
    platform :ios, '16.0'
    
    # Add Finvu SDK dependency
    pod 'FinvuSDK', :git => 'https://github.com/Cookiejar-technologies/finvu_ios_sdk.git'

    # Install all Flutter iOS dependencies
    flutter_install_all_ios_pods File.dirname(File.realpath(__FILE__))
end

# Post installation configuration
post_install do |installer|
    installer.pods_project.targets.each do |target|
        target.build_configurations.each do |config|
            # Ensure consistent iOS version across all pods
            config.build_settings['IPHONEOS_DEPLOYMENT_TARGET'] = '16.0'
        end
    end
end`}
                                    language="ruby"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Initialize the SDK:
                                </Typography>
                                <CopyBlock
                                    text={`// Get shared instance
let finvuManager = FinvuManager.shared

// Initialize with config
let finvuUrl = URL(string: "wss://webvwdev.finvu.in/api")!
let finvuClientConfig = FinvuClientConfig(
    finvuEndpoint: finvuUrl
)

// Initialize the SDK
finvuManager.initializeWith(config: finvuClientConfig)`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Connection setup:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.connect { error in
    if let error = error {
        print("Connection failed: \\(error)")
        return
    }
    print("Connected successfully")
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    1. Add dependencies in your <code>pubspec.yaml</code>:
                                </Typography>
                                <CopyBlock
                                    text={`# pubspec.yaml
dependencies:
    flutter:
        sdk: flutter

    # Finvu SDK dependencies
    finvu_flutter_sdk:
        git:
            url: https://github.com/Cookiejar-technologies/finvu_flutter_sdk.git
            path: client    # SDK client package
            ref: 1.0.1     # Specific version tag

    finvu_flutter_sdk_core:
        git:
            url: https://github.com/Cookiejar-technologies/finvu_flutter_sdk.git
            path: core     # SDK core package
            ref: 1.0.1     # Specific version tag`}
                                    language="yaml"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    2. Initialize the SDK:
                                </Typography>
                                <CopyBlock
                                    text={`// Get factory instance
final FinvuManager finvuManager = FinvuManager(); // Factory constructor returns same instance

// Initialize the SDK
finvuManager.initialize(FinvuConfig(
    finvuEndpoint: "wss://wsslive.finvu.in/consentapi"
));`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    3. Connect to Finvu server:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    await finvuManager.connect();
    print('Connected successfully');
} catch (e) {
    print('Connection failed: \$e');
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* SDK Access & Initialization Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                SDK Access & Initialization
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                The <code>FinvuManager</code> class provides access to all SDK APIs. Initialize it in your application's entry point.
            </Typography>

            <Box sx={{ mt: 3 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* SDK Access & Initialization Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Access the singleton instance and initialize:
                                </Typography>
                                <CopyBlock
                                    text={`// Get singleton instance
private val finvuManager = FinvuManager.shared

// Initialize with config
private val baseUrl = "wss://webvwdev.finvu.in/api"
private val finvuClientConfig = FinvuClientConfig(finvuEndpoint = baseUrl)

// Initialize the SDK
finvuManager.initializeWith(finvuClientConfig)`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Access the shared instance and initialize:
                                </Typography>
                                <CopyBlock
                                    text={`// Get shared instance
let finvuManager = FinvuManager.shared

// Initialize with config
let finvuUrl = URL(string: "wss://webvwdev.finvu.in/api")!
let finvuClientConfig = FinvuClientConfig(
    finvuEndpoint: finvuUrl
)

// Initialize the SDK
finvuManager.initializeWith(config: finvuClientConfig)`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Access the factory instance and initialize:
                                </Typography>
                                <CopyBlock
                                    text={`// Get factory instance
final FinvuManager finvuManager = FinvuManager(); // Factory constructor returns same instance

// Initialize the SDK
finvuManager.initialize(FinvuConfig(
    finvuEndpoint: "wss://wsslive.finvu.in/consentapi"
));`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>

                    {/* Connection Setup Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Connect to the Finvu server with error handling:
                                </Typography>
                                <CopyBlock
                                    text={`finvuManager.connect { result ->
    runOnUiThread {
        if (result.isSuccess) {
            Toast.makeText(this, "Connected", Toast.LENGTH_SHORT).show()
        } else {
            when ((result.exceptionOrNull() as FinvuException).code) {
                // Handle specific error cases
            }
        }
    }
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Establish connection with completion handler:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.connect { error in
    if let error = error {
        print("Connection failed: \\(error)")
        return
    }
    print("Connected successfully")
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Connect using async/await pattern:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    await finvuManager.connect();
    print('Connected successfully');
} catch (e) {
    print('Connection failed: \$e');
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* Authentication Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Authentication
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Authentication involves two steps: initiating login with a consent handle and verifying the OTP received.
            </Typography>

            {/* Login with Consent Handle */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. Login with Consent Handle
            </Typography>

            <Box sx={{ mt: 2 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Authentication Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Login with consent handle:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.loginWithUsernameOrMobileNumber(
    username = username,
    mobileNumber = mobileNumber,
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle login error
        return@loginWithUsernameOrMobileNumber
    }
    val otpReference = result.getOrThrow()
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Verify OTP:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.verifyLoginOtp(
    otp = otp,
    otpReference = otpReference
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle verification error
        return@verifyLoginOtp
    }
    val handleInfo = result.getOrThrow()
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Login with consent handle:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.loginWithUsernameOrMobileNumber(
    username: username,
    mobileNumber: mobileNumber
) { otpReference, error in
    if let error = error {
        // Handle login error
        return
    }
    // Store otpReference for verification
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Verify OTP:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.verifyLoginOtp(
    otp: otp,
    otpReference: otpReference
) { handleInfo, error in
    if let error = error {
        // Handle verification error
        return
    }
    // Process handleInfo
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Login with consent handle:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    final otpReference = await _finvuManager.loginWithUsernameOrMobileNumber(
        username: username,
        mobileNumber: mobileNumber,
    );
    // Store otpReference for verification
} catch (e) {
    // Handle login error
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Verify OTP:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    final handleInfo = await _finvuManager.verifyLoginOtp(
        otp,
        otpReference,
    );
    // Process handleInfo
} catch (e) {
    // Handle verification error
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* Account Discovery Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Account Discovery
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                Account discovery is a two-step process: first fetching available FIP options, then initiating the discovery flow with the selected FIP.
            </Typography>

            {/* Fetch FIP Options */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. Fetch FIP Options
            </Typography>

            <Box sx={{ mt: 2 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Account Discovery Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    First, fetch FIP details:
                                </Typography>
                                <CopyBlock
                                    text={`// Get FIP details first
FinvuManager.shared.fetchFIPDetails(fipId = "FIP_ID") { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        return@fetchFIPDetails
    }
    val fipDetails = result.getOrThrow()
}

// Then fetch all FIP options
FinvuManager.shared.fetchAllFIPOptions() { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        return@fetchAllFIPOptions
    }
    val fipOptions = result.getOrThrow()
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Initiate account discovery:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.discoverAccounts(
    finvuFipDetails = fipDetails,
    fiTypes = listOf("DEPOSIT", "TERM-DEPOSIT"),
    finvuIdentifiers = listOf(
        FinvuIdentifier(
            category = "STRONG", 
            type = "MOBILE", 
            value = "1234567890"
        )
    )
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        return@discoverAccounts
    }
    val discoveredAccounts = result.getOrThrow()
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Fetch FIP details and options:
                                </Typography>
                                <CopyBlock
                                    text={`// Get FIP details
FinvuManager.shared.fetchFIPDetails(fipId: "FIP_ID") { details, error in
    if let error = error {
        // Handle error
        return
    }
    let fipDetails = details
}

// Fetch all FIP options
FinvuManager.shared.fetchAllFIPOptions { options, error in
    if let error = error {
        // Handle error
        return
    }
    let fipOptions = options
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Discover accounts:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.discoverAccounts(
    fipDetails: fipDetails,
    fiTypes: ["DEPOSIT", "TERM-DEPOSIT"],
    identifiers: [
        FinvuIdentifier(
            category: "STRONG",
            type: "MOBILE",
            value: "1234567890"
        )
    ]
) { accounts, error in
    if let error = error {
        // Handle error
        return
    }
    // Process discovered accounts
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Fetch FIP details and discover accounts:
                                </Typography>
                                <CopyBlock
                                    text={`// Fetch FIP details
try {
    final fipDetails = await _finvuManager.fetchFIPDetails("FIP_ID");
    
    // Fetch all FIP options
    final fipOptions = await _finvuManager.fetchAllFIPOptions();

    // Discover accounts
    final discoveredAccounts = await _finvuManager.discoverAccounts(
        fipDetails,
        ["DEPOSIT", "TERM-DEPOSIT"],
        [
            FinvuIdentifier(
                category: "STRONG",
                type: "MOBILE",
                value: "1234567890"
            )
        ],
    );
} catch (e) {
    // Handle errors
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* Account Linking Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Account Linking
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                After discovering accounts, you can initiate the linking process. This is typically a two-step process: initiating the link and confirming with OTP.
            </Typography>

            {/* Initiate Account Linking */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. Initiate Account Linking
            </Typography>

            <Box sx={{ mt: 2 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Account Linking Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Initiate account linking:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.linkAccounts(
    finvuAccounts = selectedAccounts,
    finvuFipDetails = fipDetails
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle error
        return@linkAccounts
    }
    val response = result.getOrThrow()
    val accountLinkingRequestReference = response.referenceNumber
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Confirm account linking with OTP:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.confirmAccountLinking(
    referenceNumber = accountLinkingRequestReference,
    otp = otp
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle error
        return@confirmAccountLinking
    }
    val response = result.getOrThrow()
    // Process successful linking
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Initiate account linking:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.linkAccounts(
    accounts: selectedAccounts,
    fipDetails: fipDetails
) { response, error in
    if let error = error {
        // Handle error
        return
    }
    let accountLinkingRequestReference = response.referenceNumber
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Confirm account linking with OTP:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.confirmAccountLinking(
    linkingReference: AccountLinkingRequestReference(
        referenceNumber: accountLinkingRequestReference
    ),
    otp: otp
) { response, error in
    if let error = error {
        // Handle error
        return
    }
    // Process successful linking
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Link and confirm accounts:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    // Initiate account linking
    final linkingResponse = await _finvuManager.linkAccounts(
        selectedAccounts,
        fipDetails,
    );
    
    // Get reference number for OTP confirmation
    final referenceNumber = linkingResponse.referenceNumber;
    
    // Confirm account linking with OTP
    await _finvuManager.confirmAccountLinking(
        referenceNumber,
        otp,
    );
    
    // Account linking successful
} catch (e) {
    // Handle linking errors
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* Consent Management Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Consent Management
            </Typography>

            <Typography sx={{ mt: 2, color: "#555", lineHeight: 1.7 }}>
                After linking accounts, you can manage consent requests by either approving or denying them based on user input.
            </Typography>

            {/* Approve Consent */}
            <Typography variant="subtitle1" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                1. Approve Consent Request
            </Typography>

            <Box sx={{ mt: 2 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* Consent Management Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Approve consent request:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.approveConsentRequest(
    consentDetails = consentDetails,
    selectedAccounts = selectedAccounts
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle error
        return@approveConsentRequest
    }
    val response = result.getOrThrow()
    // Process successful approval
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Deny consent request:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.denyConsentRequest(
    consentRequestDetailInfo = consentRequestDetailInfo
) { result ->
    if (result.isFailure) {
        val error = result.exceptionOrNull() as FinvuException
        // Handle error
        return@denyConsentRequest
    }
    // Consent denied successfully
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Approve consent request:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.approveAccountConsentRequest(
    consentDetail: consentDetail,
    linkedAccounts: linkedAccountsInfo
) { response, error in
    if let error = error {
        // Handle error
        return
    }
    // Process successful approval
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ mt: 3, mb: 2 }}>
                                    Deny consent request:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.denyAccountConsentRequest(
    consentDetail: consentDetail
) { response, error in
    if let error = error {
        // Handle error
        return
    }
    // Consent denied successfully
}`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ mb: 2 }}>
                                    Manage consent requests:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    // Approve consent request
    await _finvuManager.approveConsentRequest(
        consentDetails,
        selectedAccounts,
    );
    
    // Or deny consent request
    await _finvuManager.denyConsentRequest(
        consentRequestDetailInfo,
    );
} catch (e) {
    // Handle consent management errors
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            {/* FAQ Section */}
            <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                Frequently Asked Questions
            </Typography>

            <Box sx={{ mt: 3, mb: 4 }}>
                <Paper sx={{ 
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}>
                    {/* Platform Selector */}
                    <Box sx={{ 
                        borderBottom: '1px solid rgba(0,0,0,0.12)',
                        display: 'flex',
                        gap: 1,
                        p: 1
                    }}>
                        {['Android', 'iOS', 'Flutter'].map((platform) => (
                            <Typography
                                key={platform.toLowerCase()}
                                onClick={() => handlePlatformChange(platform.toLowerCase())}
                                sx={{
                                    px: 2,
                                    py: 1,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    bgcolor: selectedPlatform === platform.toLowerCase() ? 'rgba(0,0,0,0.04)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                {platform}
                            </Typography>
                        ))}
                    </Box>

                    {/* FAQ Content */}
                    <Box sx={{ p: 2 }}>
                        {selectedPlatform === 'android' && (
                            <>
                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452' }}>
                                    Q: I'm getting a Kotlin version compatibility error. How do I fix it?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: The error "Class was compiled with an incompatible version of Kotlin" occurs when your Kotlin version doesn't match the SDK's requirements. Ensure that in your <code>settings.gradle</code> file, the Kotlin version is set to 1.9.0:
                                </Typography>
                                <CopyBlock
                                    text={`plugins {
    id "org.jetbrains.kotlin.android" version "1.9.0" apply false
}`}
                                    language="groovy"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452', mt: 4 }}>
                                    Q: How do I set up GitHub authentication for the SDK?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: You'll need to set up GitHub Personal Access Token (PAT) with package read permissions. Add these to your environment variables or gradle.properties:
                                </Typography>
                                <CopyBlock
                                    text={`GITHUB_PACKAGE_USERNAME=your_github_username
GITHUB_PACKAGE_TOKEN=your_github_pat`}
                                    language="properties"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452', mt: 4 }}>
                                    Q: How do I handle WebSocket connection errors?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: The SDK provides specific error codes through FinvuException. Implement error handling based on these codes:
                                </Typography>
                                <CopyBlock
                                    text={`when (error.code) {
    CONNECTION_ERROR -> // Handle connection issues
    TIMEOUT_ERROR -> // Handle timeout
    AUTH_ERROR -> // Handle authentication issues
}`}
                                    language="kotlin"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'ios' && (
                            <>
                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452' }}>
                                    Q: How do I handle SSL/TLS certificate validation?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: The SDK handles certificate validation by default. For development environments, you may need to configure ATS settings in Info.plist.
                                </Typography>
                                <CopyBlock
                                    text={`<key>NSAppTransportSecurity</key>
<dict>
    <key>NSAllowsArbitraryLoads</key>
    <true/>
</dict>`}
                                    language="xml"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452', mt: 4 }}>
                                    Q: How do I debug network issues?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: Enable SDK logging by setting the log level in your configuration:
                                </Typography>
                                <CopyBlock
                                    text={`FinvuManager.shared.setLogLevel(.debug)`}
                                    language="swift"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                        {selectedPlatform === 'flutter' && (
                            <>
                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452' }}>
                                    Q: How do I handle SDK initialization errors?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: Wrap the initialization in a try-catch block and handle specific error types:
                                </Typography>
                                <CopyBlock
                                    text={`try {
    await _finvuManager.initialize(config);
} on PlatformException catch (e) {
    // Handle platform-specific errors
} on FinvuException catch (e) {
    // Handle SDK-specific errors
}`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />

                                <Typography sx={{ fontWeight: 'bold', color: '#0b3452', mt: 4 }}>
                                    Q: How do I debug platform channel issues?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: 2, color: '#555' }}>
                                    A: Enable verbose logging in your Flutter app and check platform-specific logs:
                                </Typography>
                                <CopyBlock
                                    text={`// Enable verbose logging
if (kDebugMode) {
    debugPrint('[FinvuSDK] Debug message');
}

// Check platform logs:
// Android: adb logcat | grep "FinvuSDK"
// iOS: Console.app -> search "FinvuSDK"`}
                                    language="dart"
                                    showLineNumbers={true}
                                    theme={dracula}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            <DocNavigation currentPath={location.pathname} />
        </Box>
    );
});

export default MobileSDKIntegration;
